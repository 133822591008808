var translations_en = {
  "ACCEPTED": "Accepted",
  "ACCOUNT": "Account",
  "ACTIONS": "Actions",
  "ACTIVE": "Active",
  "ACTIVITY": "Company",
  "ADD": "Add",
  "ADMIN_NOTES": "Note amministrative",
  "ADVANCED_SEARCH": "Advanced search",
  "AGREEMENT": "Agreement",
  "AHEAD": "Next",
  "ALERTS": "Notifications",
  "ALERTS_PLACEHOLDER": "Enter any notifications here to be shown when viewing the profile or assigning a task",
  "AMOUNT": "Amount",
  "ASK_CONTINUE": "Continue?",
  "BACK": "Back",
  "BARCODE": "Barcode",
  "BATCH": "Batch/Workflow",
  "BETA_BANNER": "Door24 beta release",
  "BETA_BANNER_INFO": "Please send notification of any operating anomalies documented by screenshots to:",
  "CA": "CA",
  "CANCEL": "Cancel",
  "CANCEL_MOD": "Cancel changes",
  "CASHED": "Cashed",
  "CLEAR": "Clear",
  "CLICK_HERE": "click here",
  "CLOSE": "Close",
  "COD": "Cod",
  "CONFIRM": "Confirm",
  "CONTRIBUTOR": "Contributor",
  "CONTRIBUTOR_SHORT": "Coll.re",
  "CONTRIBUTORS": "Contributors",
  "COOKIE_POLICY": "Cookie policy",
  "COOKIE_POLICY_URL": "https://www.landoor.com/en/privacy-policy/",
  "COST": "Cost",
  "COSTS": "Costs",
  "COUNTER": "Counter",
  "CREATE": "Create",
  "CREATED_AT": "Data inserim. prev./ordine",
  "CREATE_ACCOUNT": "Create or modify account",
  "CURRENCY": "Currency",
  "CURRENCY_ID": "Currency ID",
  "CURRENT": "Current",
  "CUSTOMER": "Customer",
  "CV": "Curriculum Vitae",
  "DATE": "Date",
  "DATE_EG": "e.g. 01/01/2000",
  "DATE_FORMAT": "dd/MM/yyyy",
  "DATE_SELECT": "Select a date",
  "DATE_TIME_FORMAT": "dd/MM/yyyy HH:mm",
  "DELETE": "Delete",
  "DELETE_SUCCESS": "Delete success",
  "DEMO": "Demo",
  "DESTINATION": "Destination",
  "DETAIL": "Detail",
  "DISASSOCIATE": "Disassociate",
  "DISCOUNT": "Discount",
  "DISCOUNTS": "Discounts",
  "DOCUMENTS": "Documents",
  "DOWNLOAD": "Download",
  "DOWNLOAD_CSV": "Export in CSV format",
  "DOWNLOAD_CSV_DONE_NOT_BILLED_TASKS": "Esporta i task non fatturati",
  "DOWNLOAD_JSON": "Export the latest changes in JSON",
  "DOWNLOAD_REPORT": "Scarica report",
  "DOWNLOAD_REPORT_SUCCESS": "Report in fase di elaborazione, \nsarà inviato al tuo indirizzo email.",
  "DOWNLOAD_REQUEST": "Do you want to export the list?",
  "DOWNLOAD_REQUEST_ALL_COLUMNS": "Abilita il cursore accanto a \"Mostra tutte le colonne della tabella\" per esportare tutte le colonne.",
  "DUPLICATE": "Duplicate",
  "EMAIL": "E-mail",
  "ENABLE": "Enable",
  "ERROR": "Error",
  "EXCHANGE_RATE": "Exchange rate",
  "EXIT": "Exit",
  "EXPAND": "Expand",
  "EXPIR": "Expir.",
  "EXPIRATION": "Expiration",
  "EXPORT_EMAIL_SUCCESS_1": "Riceverai all'indirizzo email utilizzato per il login il link per scaricare l'esportazione.",
  "EXPORT_EMAIL_SUCCESS_2": "Se non lo ricevi entro 15 minuti, controlla la cartella SPAM oppure effettua una nuova esportazione.",
  "EXTERNAL_CODE": "External code",
  "FILTER": "Filter",
  "FILTERS": "Filters",
  "FOLLOW_UP_NOTES": "Follow-up notes",
  "FROM": "From",
  "GIFTS": "Gifts",
  "GLOBAL": "Global",
  "GOTO": "Go to",
  "GOTO_LIST": "Go to the list",
  "HARDWARE": "Hardware",
  "HI": "Hi",
  "HOME": "Home",
  "IBAN": "IBAN",
  "INFO_196_03_LABEL": "INFORMATION PURSUANT TO LEGISLATIVE DECREE NO. 196/03 REGARDING THE PROTECTION OF PERSONAL DATA",
  "INFO_196_03_MESSAGE": "I have read the privacy policy and express my consent to the processing and communication of my data to companies that perform functions that are necessary or instrumental to the operation of the Service, which, for the same purposes, will also be able to send this data to third parties. Without my consent, the service cannot be provided.",
  "INSERT": "Enter",
  "LABEL": "Label",
  "LANGUAGES": "Languages",
  "LIST": "List",
  "LOGIN": "Login",
  "MFA_CODE": "Codice",
  "MIN_3_CHARS": "minimum 3 characters",
  "MODIFY": "Modify",
  "NEW": "New",
  "NEXT": "Next",
  "NO": "No",
  "NO_APPROVE": "Do not approve",
  "NONE": "Nothing selected",
  "NOT_ACCEPTED": "Not accepted",
  "NOTE": "Note",
  "NOTE_ADMIN": "Administrative notes",
  "NO_DATE": "No date",
  "NO_LIST": "No item in the list",
  "NO_NOTE": "No note",
  "NO_RESULTS": "No results",
  "NO_RESULT_FOR": "No results for",
  "NO_SELECTED": "No item selected",
  "NUMBER": "Number",
  "OPEN_LINK_TARGET_BLANK": "Open in new tab",
  "OTHER": "Other",
  "PARTIAL": "Partial",
  "PASSWORD": "Password",
  "PREFIX": "Prefix",
  "PREVIOUS": "Previous",
  "PRICE": "Price",
  "PRICES": "Prices",
  "PRIMARY": "Primary",
  "PRINT": "Print",
  "PRIVACY": "Privacy",
  "PRIVACY_POLICY": "Privacy policy",
  "PRIVACY_POLICY_URL": "https://www.landoor.com/en/privacy-policy/",
  "PROJECT": "Project",
  "PROJECTS": "Projects",
  "QUANTITY": "Quantity",
  "QUANTITY_SHORT": "Qty",
  "RATE": "Rate",
  "READ": "Read",
  "REVENUE": "Revenue",
  "SAVE": "Save",
  "SAVE_REQUEST": "The data entered is not saved. Save or discard the changes before exiting",
  "SDI": "SDI code",
  "SEARCH": "Search",
  "SECTOR": "Sector",
  "SECTORS": "Sectors",
  "SELECT": "Select",
  "SELECT_ALL": "Select all",
  "SELECT_CONTRIBUTOR": "Select contributor",
  "SELECT_CONTRIBUTORS": "Select contributors",
  "SELECT_FILE": "Upload file",
  "SENDING_DATE": "Sending date",
  "SERVICE": "Service",
  "SERVICE_LEVEL_AGREEMENT": "Service level agreement",
  "SETTINGS": "Settings",
  "SHOW_ALL": "Show all",
  "SHOW_LESS": "Mostra meno",
  "SHOW_MORE": "Show more",
  "SOFTWARE": "Software",
  "SOFTWARE_SHORT": "SW",
  "STATUS": "State",
  "STYLEGUIDE": "Styleguide",
  "SUFFIX": "Suffix",
  "TASK": "Task",
  "TAX": "Tax",
  "TAXABLE": "Taxable",
  "TEMPLATE": "Template",
  "TERMS": "Terms and conditions",
  "TERMS_URL": "https://www.landoor.com/en/termini-condizioni/",
  "THANKS": "Thanks",
  "TO": "To",
  "TO_BE_DEFINED": "To be defined",
  "TODAY": "Today",
  "TOTAL": "Total",
  "TYPE": "Type",
  "UM": "UM",
  "UNAVAILABLE": "Unavailable",
  "UNDO": "Cancel",
  "UNIT_PRICE": "Unit price",
  "UNSPECIFIED": "Not specified",
  "UNTIL": "until",
  "UOM": "Measurement unit",
  "DESCRIPTION_TASK": "Task Description",
  "UPDATE": "Update",
  "UPDATED": "Updated",
  "USER": "User",
  "USERS": "Users",
  "VAT": "VAT",
  "VAT_CODE": "Codice IVA",
  "VOICE": "Voice",
  "WEBSITE": "Website",
  "WEIGHT": "Weight",
  "YEAR": "Year",
  "YES": "Yes",
  "WAITING": "Pending",
  "admin": {
    "ADMINS": "Admins",
    "ACCOUNT_ROLE": "Door24 role account",
    "CREATE_ADMIN": "Create a new admin",
    "SELECT_ROLE": "Select role"
  },
  "auth": {
    "ACCEPT_LICENCE": "Accept the license terms",
    "BACK_TO_LOGIN": "Torna al login",
    "FORCE_RESET_PASSWORD": "Hai superato il limite di tentativi consentito. Cambia la tua password per accedere di nuovo.",
    "FORGOTTEN_ASK": "Forgot password?",
    "FORGOT_INFO": "Enter your e-mail address. You will be sent a message with a link to create a new password.",
    "LOGIN_ERROR": "Incorrect email or password",
    "MFA_LOGIN_ERROR": "Il codice è errato oppure scaduto",
    "MFA_LOGIN_MSG_1": "È stato inviato alla tua casella email il codice per completate il login, inseriscilo qui sotto.",
    "MFA_LOGIN_MSG_2": "Se non lo ricevi entro qualche minuto, controlla la cartella SPAM.",
    "MFA_LOGIN_MSG_3": "Per ricevere un nuovo codice, torna al login e ripeti la procedura.",
    "NEW_PASS_SENT": "We have sent you a new password. Please try to log in again.",
    "NO_EMAIL_FOUND": "The address you entered does not match any registered user",
    "REMEMBER_PASSWORD": "Remember password",
    "SIGNUP": "Register",
    "SIGNUP_ERROR": "Your registration request could not be completed",
    "SIGNUP_REQ_SENT": "You will receive an email to complete the registration request"
  },
  "company": {
    "ACQUISITION_SOURCE": "Acquisition Source",
    "ACTIVE": "Active company",
    "ACTIVE_BTN": "Click to activate",
    "ACTIVITY_MACROSECTOR": "Company macrosector",
    "ACTIVITY_SECTOR": "Company sector",
    "AMOUNT_EXPIRED": "Amount expired",
    "ASSOCIATED": "Clienti Associati",
    "ASK_ACTIVE": "Do you want to activate the company?",
    "ASK_DEACTIVE": "Do you want to deactivate the company?",
    "ASSOCIATE": "Associate",
    "ASSOCIATE_LIST_ALREADY_MSG": "List already exists. Do you want to add it anyway?",
    "ASSOCIATE_LIST_MSG": "Associate list to the customer?",
    "ASSOCIATE_TO_COMPANY": "Associate to existing customer",
    "SHOW_COMPANY_DOCUMENTAL": "Show in documental",
    "ASSOCIATION_ERROR": "Association error",
    "ASSOCIATION_SUCCESS": "Association success",
    "BILLING": "Billing information",
    "BILLING_COMPANIES": "Billing information",
    "BILLING_MESSAGE": "Seleziona dalla lista sotto per modificare l'intestazione",
    "BUSINESS_NAME": "Company name",
    "BU_NAME": "Business Unit name",
    "CERTIFICATION": "Certification",
    "CERTIFICATIONS": "Certifications",
    "CERTIFICATIONS_OTHER": "Other",
    "CERTIFICATIONS_OTHER_LABEL": "Enter any other certifications here",
    "CHECK_TRADOS_ERR": "This customer’s price lists do not include CAT ranges",
    "COMPANY_HEADQUARTERS": "Company headquarters",
    "CONDITIONS_CONFIRM": "Confirm conditions",
    "CONDITIONS_CONFIRM_HELP": "Flag if the customer has to confirm the terms and conditions of supply (SLA, Service Level Agreement) with each job upload or request for a quote. Do not flag if the customer already has a an order framework.",
    "CREATE_COMPANY": "Create a new company",
    "CUSTOMER": "Customer",
    "CUSTOMERS": "Customers",
    "CUSTOMIZING_SERVICES": "Customization services",
    "CUSTOM_TASKS": "Custom tasks",
    "DEACTIVE_BTN": "Click to deactivate",
    "DELETE_24H_PROJECT": "Delete the project?",
    "DELETE_TEMPLATE_PROJECT": "Delete the project?",
    "DELETE_ACTIVITY": "Delete company",
    "DELETE_EMPTY_ACT": "Select a macrosector, or delete company.",
    "DELETE_CUSTOM_SERVICE_MESSAGE": "Do you want to delete the custom service?",
    "DELETE_LIST_MSG": "Permanently delete list?",
    "DISABLE_EXPIRED_AMOUNT_ALERT": "Disabilita avviso insoluto",
    "DOC_MACROSECTOR": "Document macrosector",
    "DOC_SECTOR": "Document sector",
    "DOC_TYPE": "Document type",
    "EMAIL_CC": "CC e-mail",
    "EMAIL_CC_INFO": "Email address to which all messages will be CC’ed",
    "EMAIL_INVOICE": "Billing notification e-mail",
    "EMAIL_INVOICE_HELP": "Email address to which you want invoices and requests for their approval sent",
    "EMAIL_INVOICE_PLACEHOLDER": "Email address to which you want invoices sent",
    "EMPLOYEES_NUMBER": "Number of employees",
    "EXPIRED_MESSAGE": "Il cliente ha un importo scaduto di euro",
    "FORM": "Customer details",
    "FOUNDATION_YEAR": "Year of foundation",
    "FRAMEWORK_AGREEMENT": "Framework Agreement",
    "GOTO": "Go to the company",
    "HEADQUARTER": "Headquarters",
    "HEADQUARTERS": "Headquarters",
    "HEADQUARTERS_MESSAGE_DELETE": "Do you want to delete the operational headquarters?",
    "HEADQUARTER_ID": "Headquarter ID",
    "HEADQUARTER_NAME": "Name of headquarters",
    "JOINT_VENTURE": "Joint venture",
    "LIST": "List",
    "LISTS": "Lists",
    "MAILING": "Mailing",
    "NO_EMAIL_LEGAL_ADDRESS": "The legal headquarters does not have an e-mail address",
    "NO_HEADQUARTER": "No headquarters",
    "NOT_ACTIVE": "Company not active",
    "NO_SECTORS": "No selectable sector",
    "OVERWRITE_LISTS_MSG": "Overwrite the current list?",
    "OWN": "Invoice recipient",
    "PAYMENT_CONDITIONS": "Payment Terms",
    "PAYMENT_MODE": "Payment method",
    "PMS_NUMBER": "Project Manager number",
    "PROJECT_CODE": "Project code",
    "PUBLIC_AUTORITY": "I am a public authority",
    "REQUIRES_TRADOS_ERR": "This customer requires measurement units with Trados price ranges. The operation could not be completed.",
    "SELECT_A_COMPANY": "Select a company",
    "SERVICE_ALREADY_INSERT": "Service already entered",
    "SET_DEFAULT_LIST": "Mark as default",
    "SET_DEFAULT_LIST_MSG": "Mark this list as the default?",
    "SPECIALIZATION_MACROSECTOR": "Specialization macrosector",
    "TASK": "Task",
    "TASKS": "Tasks",
    "TASKS_CUSTOM_VALIDATION_ERROR": "Enter at least one custom task for each service",
    "TRADOS_NOT_USED": "This customer does not use Trados",
    "TRADOS_RANGE": "Trados ranges",
    "TRADOS_RANGE_EDIT": "Edit Trados ranges",
    "UNRELIABLE": "Non usare/Inaffidabile",
    "UNRELIABLE_MESSAGE": "Il cliente è contrassegnato come \"Non usare/Inaffidabile\"",
    "USED_CONTRIBUTORS": "Collaboratori più usati",
    "ORDERLABEL": "Project code",
    "ISO17100": "Applicability 17100"
  },
  "contacts": {
    "EMAIL": "E-mail",
    "EMAIL1": "E-mail 1",
    "EMAIL2": "E-mail 2",
    "EMAIL3": "E-mail 3",
    "EMAIL_HEADQUARTER": "Headquarters e-mail",
    "ERROR_INSERT_FORM": "* Enter at least one valid address",
    "FAX": "Fax",
    "PHONE": "Phone number",
    "PHONE_CELL": "Mobile phone",
    "PHONE_HEADQUARTER": "Headquarters phone",
    "PHONE_HOME": "Home phone",
    "PHONE_SWITCHBOARD": "Switchboard phone",
    "PHONE_WORK": "Office phone",
    "ALT_PHONE": "Alternative phone",
    "ALT_PHONE_SHORT": "Alt. phone"
  },
  "contributors": {
    "ACTIVE": "Active contributor",
    "ADDED_INFO": "Additional information",
    "ADD_MONOLINGUAL_SERVICES": "Add monolingual services",
    "ADD_SECTORS": "Add sectors",
    "ADD_SERVICE": "Add a service",
    "ADD_SERVICES": "Add services",
    "AMOUNT": "Contributor amount",
    "ASK_ACTIVE": "Do you want to activate the contributor?",
    "ASK_DEACTIVE": "Do you want to deactivate the contributor?",
    "ASSOCIATION": "Professional association",
    "ASSOCIATIONS": "Professional associations",
    "AVAILABILTY_FOLDER": "Daily availability in standard pages",
    "AVAILABILTY_HOUR": "Daily availability in hours",
    "AVAILABILTY_TREAT": "Willingness to negotiate",
    "AVAILABILTY_WORD": "Daily availability in words",
    "BILLING": "Invoice",
    "BLACKLISTED_WARNING": "Non è possibile aggiungere il collaboratore perché è nella blacklist del committente.",
    "BROCHURE": "Upload a presentation brochure",
    "CERTIFICATION": "Certificazione professionale",
    "CERTIFICATIONS": "Certificazioni professionali",
    "COMPLETE_PERC": "Complete profile",
    "COMPLETE_PERC_SHORT": "% profile",
    "CONNECTION": "Regular access",
    "CONTRIBUTOR": "Contributor",
    "CONTRIBUTORS": "Contributors",
    "CREATE_CONTRIBUTOR": "Create a new contributor",
    "CV_ERROR_INSERT": "* Upload a file",
    "DELETE_FILE_MESSAGE": "Do you want to delete the file?",
    "DELETE_VOTE": "Do you want to delete the vote?",
    "DETAIL": "Dettaglio collaboratore",
    "DETAIL_PL": "Dettaglio collaboratori",
    "DICTIONARY_INSTALLED": "Installed dictionaries",
    "DICTIONARY_ONLINE": "Online dictionaries",
    "DOCUMENT_TYPE_VOTE": "Vote by type of document",
    "DOCUMENTARY": "Documentale",
    "DOCUMENTARY_ACCESS": "Accesso al Documentale",
    "DOCUMENTARY_ACCESS_FOR_CUSTOMERS": "Clienti per cui il collaboratore è autorizzato:",
    "DOCUMENTARY_ADD_COMPANY": "Aggiungi cliente",
    "EDIT_TRADOS_WARNING": "Per utilizzare le fasce Trados per il collaboratore, lasciare vuoti i campi \"Quantità\" e \"Importo unitario\"",
    "EDUCATION": "Qualification",
    "EDUCATIONS": "Qualifications",
    "EDUCATION_ERROR_INSERT": "* Enter at least one qualification",
    "EDUCATION_PLACE": "Awarded by",
    "EXPERIENCE": "Work experience / Role / Job",
    "EXPERIENCES": "Work experiences",
    "EXPERIENCE_PLACE": "At / on behalf of",
    "FILTER_ISO17100": "Suitable for ISO 17100",
    "EXPORT_SPECIALIZATIONS_CSV": "Esporta valutazioni specializzazioni",
    "EXPORT_SPECIALIZATIONS_TOTALS_CSV": "Esporta totali valutazioni",
    "FROM_CAFE": "From internet point/café",
    "FROM_HOME": "From home",
    "FROM_PERSONAL_OFFICE": "From personal office",
    "FROM_THIRD_OFFICE": "From third party office",
    "FROM_WHERE_CONNECT": "From where I connect",
    "GENERAL_QUALIFICATION": "Qualificazione generale",
    "GENERAL_VOTE": "General vote",
    "HAS_AGREEMENT": "Uploaded agreement",
    "HIDE_PRICES": "Nascondi tariffe",
    "HEADQUARTER_NAME": "Company name / Local business unit",
    "HOLIDAYS": "Out of office",
    "IBAN": "IBAN",
    "IBAN_SWIFT": "Swift Code",
    "INCOME": "Type of income",
    "INPS": "Social security contribution",
    "INSERT_VOTE": "Enter a vote from 1 to 10",
    "INTERPRETERS_MACROSECTOR": "Interpreting",
    "ISO_17100": "Which of the following levels of competence have you achieved?",
    "ISO_17100_NOT_REACHED": "non ha raggiunto un livello di competenza sufficiente ai fini della idoneità 17100",
    "LANG": "Display language",
    "LOCATION": "Location",
    "LOCATION_DEKSTOP": "Desktop",
    "LOCATION_LAPTOP": "Laptop",
    "LOCATION_OS": "Operating system of habitual work station",
    "LOCATION_OTHER": "Other",
    "LOCATION_PERSONAL_NAME": "Name of habitual work station",
    "LOCATION_PERSONAL_TYPE": "Type and model of habitual work station",
    "LSP": "Language service provider",
    "LSP_ABOUT": "Data sheet",
    "MACROSECTOR": "Macrosector",
    "MACROSECTOR_VOTE": "Macrosector vote",
    "MAX_COST": "Maximum cost",
    "NETWORK": "Network",
    "NETWORK_UOM": "Ulteriore unità di misura",
    "NEW_WORD_PRICE": "Tariffa Parola New",
    "NO_AGREEMENT": "No uploaded agreement",
    "NO_SELECTED_CONTRIBUTORS_MSG": "You have not selected a contributor",
    "NOT_ACTIVE": "Contributor not active",
    "NOT_NETWORK": "Non-network",
    "NO_CERTIFICATION": "No selectable certification",
    "NO_EDUCATION": "No title",
    "NO_HOLIDAY_SET": "No holidays entered. Press \"+\" to enter.",
    "NO_SOFTWARE": "You do not have any service that involves the use of software",
    "NUMBER_DAYS": "Number of days",
    "ON_HOLIDAY_UNTIL": "Out of office until",
    "OS": "Operating system",
    "PAYMENT": "Payment Terms",
    "PROBLEMS_SW_HD": "Main problems and weaknesses in the available software/hardware tools",
    "PROGRAMS_IN_COMPANY": "Commonly used programs in the company",
    "PROGRAMS_OTHERS": "Other programs",
    "QUALIFIED_DATE": "Qualification date",
    "QUALIFIED_DATE_LATER": "Qualification date subsequent to",
    "RATING": "Rating",
    "RATINGS_HISTORY": "Ratings history",
    "RDA": "RDA %",
    "RDA_TAXABLE": "Taxable RDA %",
    "SCORE": "Score",
    "SCORE_SHORT": "Scr.",
    "SCORE_INITIAL": "Initial score",
    "SCORE_UPDATED": "Updated score",
    "SEARCH": "Search for contributor",
    "SECTOR": "Sector",
    "SECTOR_ALREADY_INSERT": "Sector already entered",
    "SELECT_A_CONTRIBUTOR_FIRST_1": "Seleziona prima un collaboratore",
    "SELECT_A_CONTRIBUTOR_FIRST_2": "utilizzando il bottone \"Task\" nell'anagrafica di un collaboratore",
    "SELECT_NATIVE_MESSAGE": "Select a native language",
    "SELECT_SECTOR_MESSAGE": "Select a sector",
    "SELECT_SOURCE_LANGS": "Select the native languages",
    "SELECT_TASK_MESSAGE": "Select a service",
    "SEND_UPDATE_REQUEST_1": "Submit an update",
    "SEND_UPDATE_REQUEST_2": "profile request",
    "SEND_UPDATE_REQUEST_CONFIRM": "Do you want to send an update profile request to the contributor?",
    "SEND_UPDATE_REQUESTS_CONFIRM": "Do you want to send an update profile request to every selected contributor?",
    "SEND_UPDATE_REQUEST_SUCCESS": "Request successfully submitted",
    "SERVICES": "Services",
    "SERVICES_BILINGUAL": "Bilingual services",
    "SERVICES_MONOLINGUAL": "Monolingual services",
    "SERVICES_OFFERED": "Services offered",
    "SERVICES_OTHER": "Other services",
    "SPECIALIZATIONS": "Specializations",
    "SPECIALIZATIONS_SHORT": "Spec.",
    "SPECIFY": "Specify",
    "SPEED_DOWNLOAD": "Download connection speed (Mbps)",
    "SPEED_TEST_1": "Actual connection speed: connect to the site",
    "SPEED_TEST_2": "and check the values",
    "SPEED_UPLOAD": "Upload connection speed (Mbps)",
    "STATUS_ACTIVE": "Available",
    "STATUS_APPROVED": "Approved",
    "STATUS_DELETED": "Deleted",
    "STATUS_NOT_APPROVED": "Not approved",
    "STATUS_POTENTIAL": "Potential",
    "STATUS_QUALIFIED": "Qualified",
    "SUGGESTIONS_NOTE": "Suggestions/notes",
    "SUPPLIER": "Supplier",
    "TASK": "Task",
    "TASK_ALREADY_INSERT": "Service already entered",
    "TAX_CODE": "Tax code",
    "TOOLS": "Work tools",
    "TRADOS_RANGES": "Fasce Trados Collaboratore",
    "TRADOS_LOAD_RANGES": "Precompila tariffe Collaboratore",
    "TRAINING_OTHER": "Other IT training required",
    "TRAINING_SW_INFORMATIC": "For which generic software do you wish to receive training?",
    "TRAINING_SW_LANGUAGE": "For which linguistic software do you wish to receive training?",
    "TRANSLATION_COMBOS": "Combinazioni traduzione",
    "UNIT_AMOUNT": "Unit amount",
    "UOM": "Contributor measurement unit",
    "UOM_TYPE": "Cost type",
    "VAT": "VAT rate",
    "VIEW_INFO": "Read the information",
    "WHEN_IN_COMPANY": "When at the company",
    "WHEN_REMOTE_CONNECTION": "When remotely connected with Door24",
    "utype": {
      "CONTRIBUTOR": "Contributor",
      "DTP": "DTP",
      "HOSTESS": "Hostess",
      "SUPPLIER": "Supplier"
    }
  },
  "create_account": {
    "IGNORED_PASSWORD_MSG": "La password inserita verrà ignorata se non si modifica l'indirizzo email.",
    "LOGOUT_TO_EDIT_PASSWORD_MSG": "Per modificare solo la password del tuo account, effettua il logout e clicca su \"Password dimenticata?\" nella pagina di login.",
    "EDIT_PASSWORD_ANOTHER_ACCOUNT_MSG": "Non è possibile modificare la password di un account di un altro utente.",
    "CONFIRM_ACCOUNT_MSG": "Non sarà possibile effettuare il login con il nuovo account fino al click sul link di conferma nell'email che sarà inviata all'indirizzo inserito.",
    "LOGOUT_MSG": "L'utente dovrà effettuare il logout dal vecchio account, in caso contrario non potrà utilizzare il nuovo account e gli verrà mostrato il wizard di registrazione.",
    "INVALID_EMAIL_MSG": "Se si inserisce un indirizzo email a cui non si ha accesso, l'account diventerà inaccessibile e sarà necessario rivolgersi all'assistenza."
  },
  "crud": {
    "DELETED": "Deleted!",
    "DELETED_INFO": "The item was deleted.",
    "error": {
      "11000": "The operation could not be completed. Item already exists"
    },
    "SAVED": "Successfully saved.",
    "VALIDATION_ERROR": "The entered data contains errors.",
    "VALIDATION_ERROR_VERIFY": "Check before saving."
  },
  "customers": {
    "ACTIVE": "Active customer",
    "ADD_CONTRIBUTOR_BLACKLIST": "Add contributor to the blacklist",
    "ADD_CONTRIBUTOR_BLACKLIST_MSG": "Add the contributor to the blacklist?",
    "ADD_CONTRIBUTOR_BLACKLIST_SUCC": "Contributor added to the blacklist",
    "ASK_ACTIVE": "Do you want to activate the customer?",
    "ASK_DEACTIVE": "Do you want to deactivate the customer?",
    "ASK_EXISTING_HEADQUARTER": "If you continue, the headquarters entered by the user will be overwritten by an existing one.",
    "ASK_NEW_HEADQUARTER": "If you continue, a new company headquarters will be created.",
    "ASSOCIATE_CUSTOMER": "Associate customer",
    "ASSOCIATE_EXISTING_HEADQUARTER": "Associated the user to the existing headquarters",
    "ASSOCIATE_NEW_HEADQUARTER": "Create a new headquarters and associate the user",
    "CHIEF": "Chief customer",
    "COMPANY_HEADQUARTERS": "These are the currently saved company headquarters",
    "CONTRIBUTORS_BLACKLIST": "Contributors blacklist",
    "CREATE_CUSTOMER": "Create a new customer",
    "CUSTOMER": "Customer",
    "CUSTOMERS": "Contacts",
    "DEL_CONTR_MSG": "Remove the contributor?",
    "DEL_CONTR_SUCC": "Contributor removed",
    "DEL_MSG": "Eliminare il committente?",
    "DISABLE_MAIL_BUT_OFFER": "Invia solo email di offerta",
    "HEADQUARTER_ACTION": "Do you want to associate it to one of these headquarters ignoring the entered data or do you want to create a new headquarters from this data?",
    "HEADQUARTER_NOT_REGISTERED": "It is registered with this operational headquarters, but the association must be confirmed.",
    "NOT_ACTIVE": "Customer not active",
    "NO_HEADQUARTER": "No headquarters selected"
  },
  "dashboard": {
    "DATE_RANGE": "Change interval",
    "DAY": "Day",
    "LAST_30_DAYS_SUBTITLE": "Quotes and orders in the last thirty days. Move the mouse cursor to the chart for more details.",
    "LAST_30_DAYS_TITLE": "Last 30 days"
  },
  "dialog": {
    "DELETE_ASK": "Do you want to delete the selected item? This operation cannot be undone"
  },
  "due_diligence": {
    "SUBTITLE": "Our partners from all over the world (Language Service Providers, web marketing agencies, graphic agencies, etc.) are crucial to our success, which is why we use a selection and evaluation process based on strict ISO9001 and 17100 quality standards. If you wish to collaborate with Landoor, please answer the questions in the following form: your answers will be evaluated by our Vendor Management department with the aim of starting the certification process.",
    "TITLE": "Company survey",
    "production": {
      "QUESTIONS": {
        "Q_1": "How do you ensure that you have enough resources available for every project?",
        "Q_2": "Do you use capacity planning tools? If so, which?",
        "Q_3": "Do you use Subject Matter Experts? If yes, precisely indicate the number of SMEs at your disposal for each sector and their relative daily capacity (N.B.: “Subject Matter Expert” refers to a person with experience and extensive knowledge in any field outside of translation. For example, a retired serviceperson could be of support to localization related to the military.)",
        "Q_4": "Describe the process and requirements for the recruitment and selection of Subject Matter Experts.",
        "Q_5": "Describe the whole process of a typical localization project. Are the larger and more complex projects handled differently to the smaller ones? If yes, in what way?"
      },
      "TITLE": "Production, capacity and skills"
    },
    "quality": {
      "QUESTIONS": {
        "Q_1": "Give a detailed description of your quality control process.",
        "Q_2": "Do you complete a checklist before delivering the translation? What does it involve?",
        "Q_3": "How do you ensure that your translations meet the required standards?",
        "Q_4": "How do you try to ensure that there are no errors or oversights in the texts, such as typing errors?",
        "Q_5": "How do you ensure that knowledge and skills are shared within your teams?",
        "Q_6": "How do you capitalize on customer feedback?",
        "Q_7": "Is there a quality control manager for each language combination in your company?",
        "Q_8": "What are their duties?",
        "Q_9": "What type of checks do you carry out on projects managed outside the company/sub-contracted?",
        "Q_10": "How do you ensure consistency across texts in large projects managed by more than one translator?",
        "Q_11": "Do you have experience in content-sharing and TM-sharing? If so, what type?",
        "Q_12": "Are you able to provide transcreation or marketisation services? If yes, specify."
      },
      "TITLE": "Quality control"
    },
    "story": {
      "QUESTIONS": {
        "Q_1": "Describe the history of the company",
        "Q_2": "Describe the structure of the company",
        "Q_3": "Have the company or its executives ever been involved in bankruptcy, litigation, mergers or acquisitions?",
        "Q_4": "List the company’s most important customers, specifying their business sector, the year in which collaboration began, the turnover and services provided.",
        "Q_5": "What were the three most significant company results for the year just ended?",
        "Q_6": "What are your three main goals for the current year?",
        "Q_7": "Where is production located?",
        "Q_8": "The industry is undergoing a period of rapid change. Instead of proceeding directly to large projects with medium-long timescales, increasingly more customers today require translations of small-medium documents with short timescales.  How do you approach this situation? Would you be willing to group all the projects carried out into a single cumulative order at the end of the month?"
      },
      "TITLE": "Company history"
    },
    "vendor": {
      "QUESTIONS": {
        "Q_1": "How are the company's external resources managed (suppliers - freelance contributors)? Do you have a Vendor Manager?",
        "Q_2": "What is the qualification process for these resources? Describe it in detail.",
        "Q_3": "What are the minimum requirements for the qualification of all types of resources?",
        "Q_4": "Do you do regular follow-ups with freelance contributors?",
        "Q_5": "How many resources do you regularly collaborate with?",
        "Q_6": "Which language combinations can you handle internally thanks to your in-house team?"
      },
      "TITLE": "Vendor management"
    }
  },
  "error": {
    "403_MSG": "You are not authorized to view this resource",
    "404_MSG": "We could not find what you are searching for",
    "DONT_WARRY": "Don't worry, we will soon fix the problem",
    "RECEIVE_ERR": "Data reception server error",
    "SERVER_NOT_RESPOND": "The server is not responding",
    "SOMETHING_WRONG": "There is something wrong",
    "TRY_LATER": "Please try again in a moment",
    "TASK_REQUIRES_FILE_ERROR": "Per contrassegnare questo task come eseguito, devi caricare almeno un file"
  },
  "file": {
    "ADDITIONALS": "Additional files",
    "ADMIN": "Administrative documents",
    "CREATED_AT": "Upload date",
    "DESCRIPTION": "Description",
    "DOWNLOAD_POPUP_MSG": "Download started.\nIf you cannot download the file, make sure that your browser’s pop-up blocker is disabled.",
    "DOWNLOAD_ALL": "Scarica tutti",
    "ERROR_DIMENSION": "The attachment is too large. Maximum ",
    "EXTENSION": "Extension",
    "FILES": "FILE",
    "FILE_ALREADY_EXISTS1": "The file ",
    "FILE_ALREADY_EXISTS2": " already exists. Do you want to overwrite it? Continue?",
    "INSERT_METADATA_FILE": "Select macrosector, sector and document type",
    "LOAD_COMPLETE": "File successfully uploaded",
    "MESSAGE_DELETE": "Do you want to permanently delete the file?",
    "NO_FILES": "No files uploaded",
    "NO_FILES_ERR": "At least one file must be uploaded",
    "REFERENCE": "Reference documents",
    "SOURCE": "Source files",
    "TARGET_FILES": "Processed files",
    "UPLOAD_FILES": "Upload file"
  },
  "form": {
    "ADDRESS": "Address",
    "ADDRESS_RESIDENCE": "Residence address",
    "AGREEMENTS": "Consensi",
    "BIRTH_TITLE": "Date and place of birth",
    "BLACKLIST": "Blacklist",
    "CITY": "City",
    "CIVIC_NUMBER": "Civic number",
    "CODE": "Code",
    "CODE_SUPPLIER": "Supplier/contributor code",
    "COMPANY_NAME": "Company name",
    "COMPANY_NAME_SHORT": "C. name",
    "COMPANY_NAME2": "Company name 2",
    "COMPANY_VAT": "VAT number",
    "COMPANY_VAT_NAME": "VAT number of company name",
    "COMPANY_VAT_SHORT": "VAT number",
    "CONTACTS": "Contacts",
    "CONTACTS_COMPANY": "Company addresses",
    "CONTRIBUTOR_TYPE": "Type of contributor",
    "CONTRIBUTOR_U_TYPE": "User type",
    "DATE_ACQ": "Acquisition date",
    "DATE_OF_ESTABLISHMENT": "Date of establishment",
    "DELIVERY_DATE": "Due date",
    "DELIVERY_DATE_UND": "undefined",
    "DELIVERY_TIME": "Requested delivery time",
    "DEPARTMENT": "Divisione / reparto",
    "DESCRIPTION": "Description",
    "DISTRICT": "District",
    "DISTRICT_STATE": "District/State",
    "FOR_PRICE_RANGE": "Per fascia di prezzo",
    "GENDER": "Gender",
    "GENERAL_INFO": "General info",
    "HANDLE_AGREEMENTS": "Gestisci i tuoi consensi",
    "INSERT_INFO": "Select an item and press Enter",
    "KCODE": "Personal code",
    "LAST_MODIFIED": "Last modification",
    "LEGAL_ADDRESS": "Legal headquarters",
    "LINKEDIN": "Linkedin profile",
    "NAME": "First name",
    "NATION": "Country",
    "NATION_CODE": "Nation code",
    "NOTE": "Note",
    "N_ORDER": "Order number",
    "N_ORDER_PO": "PO number",
    "OPERATIVE_ADDRESS": "Operational headquarters",
    "OPERATIVE_ADDRESSES": "Operative addresses",
    "PHONE": "Phone number",
    "PHYSICAL_PERSON": "Physical person",
    "PO_DATE": "PO Date",
    "PRICE_RANGE": "Price range",
    "PRICE_RANGES": "Price ranges",
    "PROFILE": "Profile",
    "REFERENT": "Referent",
    "REFERENT1": "Referent 1",
    "REFERENT2": "Referent 2",
    "REGISTRATION_DATE": "Registration date",
    "REGISTRATION_DATE_SHORT": "Reg.",
    "REQUIRED_INFO": "The fields marked with an asterix are mandatory",
    "REQUIRE_PDF": "Request PDF order",
    "REQUIRE_QUOTE": "Quote required",
    "SELECT_ALL_LANGUAGES_HERE_MSG": "Select all target languages. A new project will be created for each language.",
    "SELECT_UNIT_PLACEHOLDER": "Select a measurement unit",
    "SETTINGS": "Settings",
    "SIZE": "Size",
    "SKYPE_NAME": "Skype name",
    "STATE": "State",
    "SURNAME": "Surname",
    "TAX_CODE": "Tax code",
    "TAX_CODE_SHORT": "Tax code",
    "TITLE": "Title",
    "TITLE_OR_CODE": "Title or code",
    "TITLE_OR_CODE_PHOLDER": "Enter the order title or code",
    "VAT": "VAT number",
    "VAT_PREFIX": "VAT number (Country code/number)",
    "WEB_SITE": "Website",
    "ZIP": "ZIP code",
    "ORDERLABEL": "Project code",
    "FRAMEWORK_AGREEMENT_NUMBER": "Framework agreement number",
    "FRAMEWORK_AGREEMENT_DATE": "Framework agreement expiration date"
  },
  "gender": {
    "FEMALE": "Female",
    "MALE": "Male"
  },
  "header": {
    "CACHE_DELETED": "The cache has been cleared",
    "CLEAR_CACHE": "Clear the cache",
    "FULLSCREEN": "Full screen",
    "HIDE_SHOW_MENU": "Show/hide menu",
    "OFFLINE": "You are offline",
    "OFFLINE_INFO": "Some functions may not be available.",
    "ONLINE": "You are online"
  },
  "zoho": {
    "BULK_SYNC": "Sincronizza tutto con Zoho",
    "BULK_SYNC_MSG": "Vuoi sincronizzare tutti gli elementi con Zoho? L'operazione richiederà alcuni minuti.",
    "BULK_SYNC_SUCCESS": "La sincronizzazione è stata avviata. Controlla la data dell'ultima sincronizzazione ricaricando la pagina tra alcuni minuti.",
    "LAST_BULK_SYNC": "Ultima sincronizzazione con Zoho:",
    "SYNC": "Sincronizza con Zoho",
    "SYNC_ERROR": "Sincronizzazione con Zoho non riuscita",
    "SYNC_MSG": "Vuoi sincronizzare l'elemento con Zoho?",
    "SYNC_SUCCESS": "Sincronizzazione con Zoho completata",
    "SYNC_AT": "Sinc. Zoho"
  },
  "INVOICE_TYPES": {
    "fattura": "Invoice",
    "notaCredito": "Credit note",
    "rebate": "Rebate",
    "acconto": "Payment"
  },
  "jobs": {
    "CEO": "CEO",
    "EMPLOYEE": "Employee",
    "MANAGER": "Manager",
    "PM": "Project Manager"
  },
  "langs": {
    "EN": "English",
    "IT": "Italian",
    "NATIVE1": "Native language 1",
    "NATIVE2": "Native language 2",
    "SOURCE": "Source language",
    "TARGET": "Target languages",
    "TARGET_SING": "Lingua target"
  },
  "library": {
    "ABBR_EN": "EN abbreviation",
    "ABBR_IT": "IT abbreviation",
    "ACQUISITION_SOURCE": "Fonte di acquisizione",
    "ACQUISITION_SOURCES": "Fonti di acquisizione",
    "ACTIONS": "Actions",
    "ACTIVE": "Active",
    "ADD_LIBRARY": "Please enter a new item in the library",
    "CODE": "Code",
    "CODE_SOA": "SOA code",
    "DAYS": "Days",
    "DEADLINE_TYPE": "Deadline type",
    "DEFAULT": "Default",
    "DESC_EN": "EN description",
    "DESC_IT": "IT description",
    "DESC_REQUIRED": "Description required",
    "DESTINATION_USE_DOCUMENT": "Destinazione uso documento",
    "DOCUMENT_TYPE": "Type",
    "EXTENSION": "Extension",
    "FOREIGN": "Foreign",
    "GENERAL": "General",
    "GENERIC": "Generic",
    "ID_IVA_IPSOA": "Id Iva Ipsoa",
    "LANG_OPTIONAL": "Optional languages",
    "LANG_REQUIRED": "Required languages",
    "LANGS": "Languages",
    "LIBRARIES": "Tables",
    "LIBRARY": "Library tables",
    "NON_DEDUCIBLE": "Not deductible",
    "NO_LAYOUT": "No layout",
    "NO_LIST": "No list",
    "OBSOLETE": "Obsolete",
    "OTHER": "Other",
    "PARENT": "Parent",
    "PDF_CREATE": "Create a new pdf template",
    "PDF_DETAIL": "Change offered template",
    "PERCENT": "Percent",
    "PRICE_LIST": "Price list",
    "PRICES_LIST": "Price lists",
    "PUBLIC": "Public",
    "SECTOR": "Sector",
    "SELECT_PARENT": "Select a parent",
    "SELECT_TABLE": "Select a table",
    "SELECT_TASKS": "Select tasks",
    "SOFTWARE": "Software",
    "TASKS": "Task",
    "TASK_CREATE": "Create a new service",
    "TASK_DETAIL": "Change the task",
    "TGEST": "TEGest",
    "TGEST_WORK_CODE": "TEGest Work Code",
    "TYPE": "Type",
    "UNITS": "Measurement unit for this service",
    "VALUE": "Value",
    "VALUE_EN": "EN name",
    "VALUE_IT": "IT name",
    "WEIGHT": "Weight",
    "multiselect": {
      "nothingSelected": "Nothing selected",
      "reset": "Reset",
      "search": "Search...",
      "selectAll": "Select all",
      "selectNone": "Select none"
    },
    "IS_TRANSLATION": "Translation"
  },
  "order": {
    "CANCEL_INVOICE": "Annulla fatturazione",
    "CANCEL_INVOICE_ERR": "Si è verificato un errore con l'annullamento della fatturazione",
    "CANCEL_INVOICE_MSG": "Vuoi annullare la fatturazione?",
    "CREATE_MESSAGE_DRAFT": "Do you want to create a draft order?",
    "DELETE_REQUEST_CONFIRM": "Do you want to delete the order?",
    "DETAILS": "Details",
    "INVOICE_CONFIRM": "Confirm pro forma invoice",
    "INVOICE_CONFIRM_MSG": "Send pro forma invoice confirmation request?",
    "INVOICE_CONFIRM_SUCC": "Pro forma invoice confirmation request sent",
    "INVOICED_CONFIRM_SUCC": "Billing successful",
    "INVOICED_CONFIRM_MSG": "Do you want to invoice?",
    "INVOICE_ORDER": "Fattura ordine",
    "INVOICE_ORDER_MSG": "Vuoi fatturare l'ordine?",
    "NONE": "No orders",
    "ORDER": "Order",
    "ORDERS": "Orders",
    "ORDER_RECEIVED": "Order received",
    "OWN": "My orders",
    "REQUIRE_QUOTE": "It is necessary to sent a quote request before creating an order. Do you want to create a new quote request?",
    "SEND_REQUEST_PM_ASSIGNED_MSG": "Do you want to assign the order to the PM?",
    "SENT_AT": "Data entrata ordine",
    "INVOICE_CONFIRMED_MSG": "Do you want to confirm the billing information?",
    "INVOICE_CONFIRMED_SUCC": "Billing information successfully confirmed",
    "TO_BILL_MSG": "Do you want to mark the order as “to invoice”?",
    "TO_BILL_SUCC": "Order marked as “to invoice”",
    "SHOW_DOCUMENTAL": "Show in documental",
    "ORDER_STATUS_CHANGE": "Confirm status change?",
    "ORDER_STATUS_CHANGE_ERROR": "Cannot change order status.",
    "ACTUAL_DELIVERY_TIME_REQUIRED_ERROR": "Impostare la data di uscita effettiva nella tab \"Info generali\" e poi salvare.",
    "IS_NOT_CHILD": "L'ordine non è collegato a un altro ordine già fatturato.",
    "IS_NOT_PARENT": "L'ordine non è collegato ad altri ordini prefatturati.",
    "LINK_TO_PARENT": "Collega a ordine come prefatturato",
    "LINK_TO_PARENT_SHORT": "Collega",
    "REMOVE_LINK_TO_PARENT": "Rimuovi collegamento a ordine già fatturato",
    "LINK_TO_PARENT_ALREADY_LINKED_ERROR": "Non è possibile collegare un ordine a un altro ordine già collegato come prefatturato.",
    "LINK_TO_PARENT_EXPIRED_ERROR": "Il pacchetto selezionato è scaduto",
    "LINK_TO_PARENT_SAME_ORDER_ERROR": "Non è possibile collegare un ordine a se stesso",
    "LINK_TO_PARENT_NOT_INVOICED_ERROR": "Non è possibile collegare un ordine a un altro ordine prefatturato oppure non fatturato",
    "LINK_TO_PARENT_QUOTE_ERROR": "Non è possibile collegare un ordine a un preventivo",
    "ORDER_IS_CHILD": "L'ordine è incluso (prefatturato) in:",
    "ORDER_IS_PARENT": "L'ordine include i seguenti prefatturati:",
    "UNLINK_FROM_PARENT_SHORT": "Scollega",
    "UNLINK_FROM_PARENT_MSG": "Vuoi scollegare l'ordine?",
    "GO_TO_LINKED_ORDER": "Vai all'ordine",
    "LINK_TO_PARENT_TOTAL_INVOICED": "Totale netto fatturato",
    "LINK_TO_PARENT_TOTAL_PRE_INVOICED": "Totale netto ordini prefatturati",
    "LINK_TO_PARENT_TOTAL_REMAINING": "Totale netto residuo",
    "LINKED_TO": "Collegato a",
    "LINKED_TO_CODE": "Collegato a Codice Ordine",
    "LINKED_TO_SHORT": "Colleg.",
    "ORDER_TYPE": "Tipologia ordine",
    "ORDER_TYPE_NORMAL": "Ordine",
    "ORDER_TYPE_PACKAGE": "Pacchetto",
  },
  "password": {
    "NO_PASSWORD": "No password entered",
    "PASSWORD_STR1": "Very weak password",
    "PASSWORD_STR2": "Weak password",
    "PASSWORD_STR3": "Medium password",
    "PASSWORD_STR4": "Strong password"
  },
  "pdf": {
    "BODY": "Body",
    "CLOSING": "Closing",
    "CLOSING_PLACEHOLDER": "Enter closing text",
    "COMPANY_TITLE": "Dear",
    "CUSTOMER_TITLE": "Attn: Dear",
    "error": {
      "NO_DEFAULT_TEMPLATE_FOUND": "No default PDF template was found"
    },
    "DOWNLOAD_OFFER_PREVIEW": "Download PDF preview",
    "HEADER": "Heading",
    "HEADER_PLACEHOLDER": "Enter heading",
    "OFFER_FOR": "Offer for ",
    "OPENING": "Opening",
    "OPENING_TEXT": "Opening text",
    "OPENING_TEXT_PLACEHOLDER": "Enter opening text",
    "SUBJECT": "Subject",
    "SUBJECT_PLACEHOLDER": "Enter subject",
    "TEXT": "Text",
    "TEXT_PLACEHOLDER": "Enter text"
  },
  "quote": {
    "ADD_PROJECT": "Add project",
    "APPROVE": "Approve",
    "APPROVE_ASK": "Approve the quote?",
    "APPROVE_ASK_INFO": "An order will be sent.",
    "APPROVE_ASK_MSG": "By pressing the CONFIRM button, you approve the quote and any notes in the document in PDF format. The quote will then become an executive work order and work will begin.",
    "APPROVE_SUCCESS": "Order sent",
    "ASK_APPROVAL": "Send offer to the customer",
    "ASK_APPROVAL_MSG": "Do you want to send the offer to the customer to request approval?",
    "ASK_APPROVAL_SUCC": "Offer sent",
    "CANCEL": "Cancel quote",
    "CANCEL_MSG": "Cancel the quote?",
    "CANCEL_SUCCESS": "Quote cancelled",
    "CANNOT_LOAD_FILE": "If it is not possible to upload the documents now, please complete the following fields",
    "CANNOT_LOAD_FILE_WARNING": "If it is not possible to upload the documents now, please select a reason",
    "COMPANY": "Company",
    "CUSTOMER_COUNTRY": "Nazione cliente",
    "CUSTOMER_MACROSECTOR": "Macrosettore attività cliente",
    "CUSTOMER_SECTOR": "Settore attività cliente",
    "CUSTOMER_SOURCE": "Fonte acquisizione cliente",
    "AMOUNT_CONFIRM": "Amount confirm",
    "CONSIGNOR": "Customer",
    "CREATED_AT": "Data creazione preventivo",
    "CREATE_MESSAGE_DRAFT": "Do you want to create a draft quote?",
    "DELETE_REQUEST_CONFIRM": "Do you want to delete the quote?",
    "DELETE_REQUEST_SUCCESS": "Quote successfully deleted",
    "DELIVERY_DATE": "Delivery date",
    "DELIVERY_TIME": "Delivery time",
    "DELIVERY_DATE_EFFECTIVE": "Eff. delivery date",
    "DETAILS": "Details",
    "FILE_MODE_AFTER": "I will upload them later",
    "FILE_MODE_CANNOT": "I cannot submit them",
    "FILE_MODE_PAPER": "I will send paper copies of the files",
    "FILE_NOT_UPLOAD_DESC": "Non-upload reason",
    "FILE_NOT_UPLOAD_INFO": "Files not uploaded information",
    "FILTERS_APPLIED": "Filters applied",
    "GET_BILLING_LEGAL_DATA": "Retrieve data from legal headquarters",
    "GET_MANAGERS_DEFAULT": "RETRIEVE CUSTOMER MANAGER",
    "INVOICE_DATE": "Invoice date",
    "INVOICE_NUMBER": "Invoice number",
    "NONE": "No quote",
    "ORDER_NUMBER": "Order number",
    "OWN": "My quotes",
    "QUOTE": "Quote",
    "QUOTES": "Quotes",
    "REJECT": "Reject quote",
    "REJECT_MSG": "Reject the quote?",
    "REJECT_SUCCESS": "The quote has been rejected",
    "REQUEST_RECEIVED": "Request received",
    "REVISION": "Revision",
    "REVISION_MSG": "Increase revision number?",
    "SEND_REQUEST_CONFIRM": "Do you want to submit the quote request?",
    "SEND_REQUEST_CONFIRM_INFO": "You will no longer be able to modify the data",
    "SEND_REQUEST_CONFIRM_ASK": "Submit the quote request",
    "SEND_REQUEST_CONFIRM_SUCCESS": "Request successfully submitted",
    "SEND_REQUEST_PM_ASSIGNED": "Assign to the PM",
    "SEND_REQUEST_PM_ASSIGNED_MSG": "Do you want to assign the quote to the PM?",
    "SEND_REQUEST_PM_ASSIGNED_SUCCESS": "PM correctly assigned to the quote.",
    "SERVICE_DETAIL": "SERVICE DETAIL",
    "SHOW_FULL_TABLE": "Show all columns of the table",
    "START_DATE": "Data disponibilità",
    "START_TIME": "Ora disponibilità",
    "TOTAL_AMOUNT": "Order amount",
    "TOTAL_COSTS": "Order costs",
    "TOTAL_COSTS_PM": "PM costs",
    "TOTAL_DIRECT_COSTS": "Direct costs",
    "TOTAL_INDIRECT_COSTS": "Indirect costs",
    "TOTAL_INVOICE": "Invoice amount",
    "TOTAL_PRJ": "Projects total",
    "TOTAL_GROSS": "Gross",
    "TOTAL_PROFIT": "Profit",
    "TOTAL_SERV": "Services total",
    "PROFIT_PERCENTAGE": "Margine"
  },
  "ratings": {
    "EXCELLENT": "Excellent",
    "GOOD": "Good",
    "INSUFFICIENT": "Insufficient",
    "SUFFICIENT": "Sufficient"
  },
  "roles": {
    "ACCOUNT_MANAGER": "Sales Manager",
    "ADMIN_MANAGER": "Account Manager",
    "BUSINESS_MANAGER": "Business manager",
    "MARKETING_MANAGER": "Marketing manager",
    "PROJECT_MANAGER": "Project manager",
    "SALES_MANAGER": "Sales manager",
    "superadmin": "Administrator/superuser",
    "pm_s": "Senior project manager",
    "pm_j": "Junior project manager",
    "am": "Sales Manager",
    "admin": "Administration",
    "cf": "Suppliers’ accounting",
    "cc": "Customers’ accounting",
    "mm": "Marketing manager",
    "dtp_s": "Senior DTP",
    "dtp_j": "Junior DTP",
    "hrm": "HR manager",
    "vm": "Vendor manager",
    "customer": "Customer",
    "contributor": "Contributor"
  },
  "service": {
    "SERVICES": "Services",
    "TARGET_SAME_AS_SOURCE_ERR": "Source language and target language must be different"
  },
  "service_detail": {
    "ADD_SRV": "Add service",
    "ASK_DELETE": "Do you want to delete the service?",
    "ASSER_TYPE": "Type of court certification",
    "AUDIO_TRANSCR": "Audio transcription",
    "BOOKBINDING": "Binding",
    "BW": "Black and white",
    "CAB": "Soundproof booth",
    "CHUCHO": "Chucotage",
    "COIL": "Coil",
    "COLOR": "Colour",
    "CONSEC": "Consecutive",
    "CONTRACT": "Curator contractualization",
    "COPIES_NUM": "Number of copies",
    "CREATE_FILE_LANG": "Language of file that will be created",
    "CUR_SELECTION": "Curator selection",
    "CUSTOMER": "Customer",
    "CUST_GEAR": "Customer or location facility",
    "DAYS_NUM": "Number of days",
    "DELIVERY_FILE_FORMAT": "Delivery format",
    "DEST_COUNTRY": "Destination country",
    "DEST_FILE_FORMAT": "Delivery file format",
    "DIGITAL": "Digital",
    "EDIT_SRV": "Change service",
    "EFFECTS": "Possible effects",
    "FAIR": "Fair",
    "FEMALE_SPKR_NUM": "N. of female speakers",
    "FILE_PR_DOC": "From a digital document ",
    "FINAL_SUPPORT": "Final format",
    "FORMAT": "Format",
    "FROM_COPY": "From certified copy",
    "FROM_ORIGINAL": "From original",
    "GEAR_TYPE": "Type of equipment",
    "HOURS_PER_DAY": "Number of hours/days",
    "INS_HERE": "Enter here",
    "INTER_TYPE": "Type of interpreting",
    "LANG_BI": "Two-way interpreting (ability to reverse source and target)",
    "LEN": "Duration",
    "LOCATION": "Meeting locations/places",
    "MALE_SPKR_NUM": "N. of male speakers",
    "MANAGEMENT": "Curator management",
    "MOV_TYPE": "Mode of travel between meeting places",
    "NEGOT": "Negotiation",
    "NET": "Network",
    "NO": "No",
    "NONE": "None",
    "OFFSET": "Offset",
    "ONE": "1",
    "ORG_BY": "Organized by",
    "ORIG_FILE_LANGUAGE": "Original file language",
    "ORIG_SUPPORT": "Original format",
    "PAPERBACK": "Paperback",
    "PAPER_WEIGHT": "Paper weight",
    "PEOPLE_NUM": "N. of people who require translation for single session",
    "PRINT_FILE_FORMAT": "File format for printing",
    "PRINT_TYPE": "Type of printing",
    "PUB_HOUSE": "Publishing house",
    "QUAD": "Four-colour printing",
    "RECEIVE_FILE_LANG": "Language of file that will be received",
    "ROOM_AMP": "Room amplification",
    "SELECT_MULTIPLE_FORMAT": "Select one or more formats",
    "SELECT_MULTIPLE_LANG": "Select one or more languages",
    "SELECT_SINGLE_COUNTRY": "Select a country",
    "SELECT_SINGLE_FORMAT": "Select a format",
    "SELECT_SINGLE_LANG": "Select a language",
    "SIMUL": "Simultaneous",
    "SOURCE_FILE_FORMAT": "Source file format",
    "SRV_DET": "Service detail",
    "STAPLE": "Staple",
    "SUBJECT": "Subject",
    "SUB_POSITION": "Position of subtitles",
    "TELECONF": "Teleconference",
    "TIME_EG": "e.g.: 1:30:00",
    "TRANSEDIT": "Transedit",
    "TWO": "2",
    "YES": "Yes"
  },
  "settings": {
    "BANK": "Bank",
    "BANKS": "Banks",
    "BANK_AGENCY": "Branch",
    "BUSINESS_CONSULTANT": "Business consultant",
    "DOC_SETTINGS": "Document settings",
    "HIDE_DEADLINES": "Hide deadlines",
    "MAX_LENGTH": "Maximum of 100 characters",
    "MAX_ROW_LENGTH": "Maximum of 100 characters per row",
    "SETTINGS": "Settings",
    "SHOW_NOTES": "Show notes",
    "SHOW_PRIVACY": "Show privacy"
  },
  "signup": {
    "ACCEPT_TERMS": "I accept the terms and conditions of use",
    "ACCEPT_SLA": "I accept the service level agreement",
    "ADDRESS_SELECT": "Select from the list",
    "COMPANY_NOT_FOUND": "The company that you searched for has not been found. Click Next to create a new one or search again.",
    "COMPILE_INFO": "Fill in all the fields and click next",
    "COMPLETE_YOUR_ACCOUNT": "Complete your account",
    "COMPLIMENTS": "Compliments",
    "CREATE_ADDRESS": "Enter the address of your operational headquarters",
    "ERROR_TYPE_CONTRIBUTOR": "You cannot currently register as a Contributor",
    "FINISH": "You have completed the registration process.",
    "FINISH_INFO_1": "An administrator will verify your information.",
    "FINISH_INFO_2": "You will receive an e-mail when your account is active.",
    "LANG": "Display language",
    "LINK_TO_COMPANY": "Register or find your company",
    "NEW_ADDRESS": "Create a new address",
    "NEW_COMPANY": "Create a new company",
    "NO_ADDRESS": "No address found",
    "NO_CV_ERR": "It is mandatory to upload a CV.",
    "SEARCH_VAT": "Enter VAT number",
    "SEARCH_VAT_NAME": "Search for VAT number or company name",
    "SECTORS": "Specialization sectors",
    "SELECT_COMPANY_TO_CONTINUE": "Select the company to continue",
    "SELECT_OPERATIVE": "Choose your operational headquarters",
    "SEND_REQUEST": "Do you want to submit the registration request?",
    "SIGNUP_ASK_CONFIRM_CONTRIBUTOR": "Do you want to work with us? Continue?",
    "SIGNUP_ASK_CONFIRM_CUSTOMER": "Do you want to register as a client? Continue?",
    "SIGNUP_AS_CONTRIBUTOR": "Work with us",
    "SIGNUP_AS_CUSTOMER": "Register as a client",
    "USER_SIGNUP": "User registration",
    "USER_SIGNUP_CONTRIBUTOR": "Contributor registration",
    "USER_SIGNUP_CUSTOMER": "Customer registration",
    "USER_TYPE": "User type",
    "USER_TYPE_SELECT": "Choose a type of user",
    "WAITING": "AWAITING ACTIVATION",
    "WAITING_INFO": "A Project Manager is analysing your profile. Please wait - you will receive an e-mail when your account is active.",
    "YOUR_DATA": "Enter your information"
  },
  "status": {
    "all": "All",
    "approved": "Approved",
    "canceled": "Cancelled",
    "confirmed": "Confirmed",
    "dataconfirm": "Confirm billing information",
    "dataconfirmed": "Billing information confirmed",
    "delivered": "Delivered",
    "delivering": "Delivering",
    "done": "Done",
    "draft": "Draft",
    "expired": "Expired",
    "expiring": "Expiring",
    "inprogress": "In progress",
    "invoiced": "Invoiced",
    "notapproved": "Not approved",
    "not_confirmed": "Not confirmed",
    "pending": "Pending",
    "pm_assigned": "PM assigned",
    "progress": "In progress",
    "request_received": "Request received",
    "request_sent": "Request submitted",
    "standby": "Stand-by",
    "status": "State",
    "tobill": "To bill",
    "waiting": "To be approved",
    "billed": "Billed",
    "revisioned": "Revisioned",
    "test": "Test",
    "internal": "Landoor/Internal",
    "not_billable": "Cancelled/Not billable",
    "pre_invoiced": "Prefatturato"
  },
  "task": {
    "ADD_BATCH": "Add batch",
    "ADD_CONTRIBUTOR": "Add contributor",
    "ADD_CONTRIBUTOR_MSG": "Add the contributor?",
    "ADD_CONTRIBUTORS_MSG": "Add the contributors?",
    "ADD_CONTRIBUTOR_SUCC": "Contributor added",
    "BATCH_NAME": "Nome del batch",
    "BTN_BACK": "Back",
    "ADD_TASK": "Add task",
    "ALREADY_STARTED": "The next task has already been started",
    "AMOUNT": "Amount",
    "AMOUNT_SUM": "Amount summary",
    "CANNOT_START": "You cannot start a task for a project that has not been started or is preceded by another task that has not been completed.",
    "CONFIRMED": "The contributor has confirmed their availability",
    "CONTRIBUTOR_ACCEPT_CONDITIONS_MSG_1": "Le",
    "CONTRIBUTOR_ACCEPT_CONDITIONS_MSG_2": "CONDIZIONI GENERALI DI CONTRATTO",
    "CONTRIBUTOR_ACCEPT_CONDITIONS_MSG_3": "di Landoor Srl che definiscono gli impegni reciproci delle Parti con particolare riferimento - ma non limitatamente - alle clausole di riservatezza, privacy e non concorrenza.",
    "CONTRIBUTOR_ACCEPT_FOOTER_1": "Per confermare, clicca su ",
    "CONTRIBUTOR_ACCEPT_FOOTER_2": " e ",
    "CONTRIBUTOR_ACCEPT_LEGAL_MSG_1": "Il ",
    "CONTRIBUTOR_ACCEPT_LEGAL_MSG_2": "DISCIPLINARE RELATIVO ALLA SICUREZZA DELLE INFORMAZIONI",
    "CONTRIBUTOR_ACCEPT_LEGAL_MSG_3": "che definisce Regole di condotta ed obblighi dei collaboratori in relazione all’uso degli strumenti informatici, di Internet e della Posta Elettronica redatto anche ai sensi del provvedimento del Garante della Privacy (Deliberazione n. 13 del 1/3/2007 - pubblicata sulla GU n. 58 del 10 marzo 2007).",
    "CONTRIBUTOR_ACCEPT_MSG": "Premendo il tasto CONFERMA accetto il conferimento dell’incarico relativo al task, con il compenso indicato e alla scadenza richiesta. Con la presa in carico del lavoro confermo inoltre di aver letto e accettato integralmente i seguenti documenti:",
    "CONTRIBUTOR_ACCEPT_TITLE": "Conferma presa in carico",
    "CONTRIBUTOR_APPROVE_MSG": "By pressing the APPROVE button, I declare my availability for taking charge of the project. I shall await assignment confirmation from the Project Manager before proceeding with the job.",
    "CONTRIBUTOR_REJECT_MSG": "Refuse the task?",
    "CONTRIBUTOR_NOTES": "Contributor notes",
    "CUSTOMER_NOTES": "Customer notes",
    "COST_SUM": "Cost summary",
    "DELETE_BATCH": "Delete the batch",
    "DELETE_BATCH_MSG": "Delete the batch?",
    "DELETE_CONTRIBUTORS": "Delete contributors",
    "DELETE_CONTRIBUTORS_MSG": "Delete the contributors?",
    "DELETE_CONTRIBUTORS_SUCC": "Contributors deleted",
    "DELETE_TASK": "Delete task",
    "DELETE_TASK_MSG": "Delete the task?",
    "DELIVER_BATCH": "Mark as “delivered”",
    "DELIVER_BATCH_MSG": "Cliccando su \"Conferma\" il PM conferma di aver eseguito un controllo di qualità del testo risultante dal flusso di lavoro precedente.",
    "DELIVER_BATCH_UNDO": "Marca il batch come \"non consegnato\"",
    "DELIVER_BATCH_UNDO_MSG": "Marcare il batch come non \"non consegnato\"?",
    "DELIVERY": "Deliver",
    "DIRECT_COSTS": "Direct costs",
    "DELETE_CONTRIBUTOR_MSG": "Disassociate contributor {{name}}?",
    "DONE": "Done",
    "DONE_MSG": "Mark the task as done?",
    "DUPLICATE_BATCH": "Duplicate the batch",
    "DUPLICATE_BATCH_MSG": "Duplicate the batch?",
    "DUPLICATE_SUCCESS": "Duplication success",
    "EDIT_TRADOS_SUGGESTION": "Select a measurement unit with price ranges to upload or edit a Trados log.",
    "EDIT_TRADOS_WARNING": "Changes to Trados ranges and price ranges will only take effect after the sum total of the task is removed.",
    "ENTRY_DATE": "Entry date",
    "ERR_SERV_TASK_UNIT": "Select service, tasks and measurement units",
    "EXPIRED": "Tasks expired",
    "EXPIRING": "Tasks expiring",
    "INDIRECT_COSTS": "Indirect costs",
    "FAKE": "Fake",
    "HAMSTER": "Hamster",
    "HIDE_PROJECT_SOURCE_FILES_TO_CONTRIBUTOR": "Nascondi i file sorgenti del progetto al collaboratore",
    "HIDE_TARGET_FILES_TO_CUSTOMER": "Nascondi i file target del task al cliente",
    "INVOICE": "Invoice",
    "INVOICE_BATCH": "Invoice the batch",
    "INVOICE_BATCH_MSG": "Do you want to invoice the batch?",
    "INVOICE_BATCH_UNDO": "Annulla fatturazione batch",
    "INVOICE_BATCH_UNDO_MSG": "Vuoi annullare la fatturazione del batch?",
    "INVOICE_INVALID_FILE_FORMAT": "Invoice must be in PDF format.",
    "INVOICE_SELECTED_TASKS_ALREADY_INVOICED_ERR": "Almeno un task selezionato è già stato fatturato.",
    "INVOICE_SELECTED_TASKS_NO_INVOICED_TASK_ERR": "Seleziona almeno un task già fatturato.",
    "INVOICE_SELECTED_TASKS_NO_TASK_ERR": "Seleziona almeno un task.",
    "INVOICE_SELECTED_TASKS_NOT_DONE_ERR": "Non è possibile fatturare un task non completato.",
    "INVOICE_SELECTED_TASKS": "Fattura i task selezionati",
    "INVOICE_SELECTED_TASKS_UNDO": "Annulla fatturazione per i task selezionati",
    "INVOICE_SELECTED_TASKS_UNDO_MSG": "Vuoi annullare la fatturazione dei task selezionati?",
    "INVOICE_SELECTED_TASKS_MSG": "Vuoi contrassegnare i task selezionati come fatturati?",
    "LAST_30_DAYS_SUBTITLE": "Tasks in the last thirty days",
    "NAME": "First name",
    "NET_PROFIT": "Net profit",
    "NOTIFIED": "Start notification sent to contributor",
    "NOT_NOTIFIED": "Start notification not sent",
    "NOT_CONFIRMED": "The contributor has not confirmed their availability",
    "NO_BATCH": "No batch/workflow present",
    "NO_TASK": "No task",
    "NO_CONTRIBUTORS": "No contributor associated with the task",
    "NOT_APPROVED": "No contributor is available for the task",
    "NONE_CONFIRMED": "No contributor has confirmed their availability for the task",
    "NOT_DONE": "Not done",
    "OFFER": "Offer",
    "PARAM": "Parameters",
    "PAYMENT": "Payment",
    "PM_COSTS": "PM costs",
    "PM_HAS_TO_START_PROJECT": "The PM must start the project",
    "PM_HAS_TO_START_TASK": "The PM must start the task",
    "PROFIT": "Profit",
    "PROFIT_PERCENTAGE": "Margine",
    "QUANT": "Quantity",
    "QUANT_SHORT": "Q.ty",
    "REFERENCE_PERIOD": "Reference period",
    "REJECT_TITLE": "Why do not you want to accept the task?",
    "REJECTION_LABEL": "Select one or more reasons to continue. Once confirmed, you will not be able to cancel the operation.",
    "REJECTION_PLACEHOLDER": "Enter the reason why you do not want to approve the task",
    "REJECTION_REASON_DATE": "I want a different delivery date",
    "REJECTION_REASON_OTHER": "Other",
    "REJECTION_REASON_PART": "I can only accept part of it",
    "REJECTION_REASON_PRICE": "I want a different rate",
    "REJECTION_REASON_UNAVAILABLE": "I am not available",
    "REJECTION_REASON_NOT_FIRST": "Another contributor has accepted faster",
    "RENAME_BATCH": "Rinomina il batch",
    "SET_FAKE_MSG": "Selecting the Fake attribute will disassociate all contributors!",
    "SHOW_PROJECT_SOURCE_FILES_TO_CONTRIBUTOR": "Mostra i file sorgenti del progetto al collaboratore",
    "SHOW_TARGET_FILES_TO_CUSTOMER": "Mostra i file target del task al cliente",
    "START_MSG": "Are you sure you want to instruct the contributor to start the task?",
    "TASKS": "Task",
    "TASK_DETAIL": "Task detail",
    "TASK_INVOICED": "Task invoiced",
    "TASK_NAME": "Task name",
    "TASK_NAME_PLACEHOLDER": "Enter the task name",
    "TODO": "To d",
    "TOT_IMP": "Amount",
    "TRADOS_FOREIGN_CURRENCY_WARNING": "Le fasce Trados non sono supportate con valute diverse dall'Euro.",
    "UPLOAD_FILE": "Upload file",
    "SHOW_DOCUMENTAL": "Show in documental"
  },
  "tribute": {
    "tribute_1040": "1040",
    "NO_RDA": "Not subject to RDA"
  },
  "uoms": {
    "DAY": "Day",
    "FOLDER": "Folder",
    "FORFAIT": "Forfait",
    "HALF_DAY": "Half day",
    "HOUR": "Hour",
    "HOUR_TS": "TS hours",
    "ROW": "Row",
    "WORD": "Word"
  },
  "user": {
    "CREDENTIALS": "Login credentials",
    "EXPANDRIVE_PREFIX": "Prefisso unità ExpanDrive",
    "EXPANDRIVE_PREFIX_PLACEHOLDER": "Ad esempio: E",
    "FIRSTNAME": "First name",
    "HAS_ACCOUNT": "User with account",
    "JOB_ROLE": "Role in company",
    "LASTNAME": "Surname",
    "LOGGED_USER": "User logged in",
    "NO_ACCOUNT": "User without account",
    "NO_IMG_WARINING": "You do not have a profile picture",
    "PERMISSIONS": "Permissions",
    "PROFILE": "Profile",
    "REPEAT_PASSWORD": "Repeat password",
    "TITLE": "Title",
    "USER_ACCOUNT": "User account"
  },
  "workflow": {
    "CODE": "Code",
    "24H_PROJECTS": "24h projects",
    "ADD_BILL_ADDR": "New billing address",
    "ADD_BILL_COMP": "Add new heading",
    "ADD_BILL_COMP_VAT_ERR_1": "A new heading cannot be added with the VAT number entered.",
    "ADD_BILL_COMP_VAT_ERR_2": "You can request entry by e-mailing the Project Manager:",
    "ADD_BILL_COMP_VAT_MAIL_1": "I would like to request the entry of a new invoice heading for",
    "ADD_BILL_COMP_VAT_MAIL_2": "New heading data:",
    "ADD_PROJECTS": "Create the projects",
    "ALREADY_STARTED": "Already started",
    "ASK_SEND": "Send",
    "ASK_SEND_INFO": "After clicking on <b>Send</b>, you will no longer be able to change the quote and it will taken over by a project manager.",
    "BACK_TO_PROGRESS": "Riporta in lavorazione",
    "BILLING_NOTE": "Inserire qui informazioni aggiuntive sull’intestatario della fattura",
    "BILLING_OPTIONS": "Billing options",
    "COMP_NAME": "Company",
    "COMPANY_COUNTER": "Progressivo cliente",
    "CONDITION": "Condition",
    "CONDITIONS_OF_SUPPLY": "Supply conditions",
    "COPY_FIRST_PROJECT_CONF_MSG": "La configurazione del progetto sarà sovrascritta con la copia della configurazione del primo progetto della lista, ad eccezione dei collaboratori.",
    "COPY_FIRST_PROJECT_CONF_TOOLTIP": "Copia la configurazione del primo progetto della lista, ad eccezione dei collaboratori",
    "COPY_WORDS_NUMBER": "Copia numero di parole",
    "COPY_WORDS_NUMBER_MSG": "Verrà copiato il numero di parole e la data di consegna dal primo task di tipo \"Traduzione\" (codice task \"1\") con unità \"Parola\" (codice unità \"1\") del primo progetto in tutti gli altri task con uguale tipo e unità di misura",
    "COPY_WORDS_NUMBER_NO_COMPANY_ERROR": "Non è stato assegnato un cliente.",
    "COPY_WORDS_NUMBER_NO_PROJECTS_ERROR": "Non sono presenti progetti.",
    "COPY_WORDS_NUMBER_TASK_NOT_FOUND_ERROR": "Non è presente nel primo progetto un task di tipo \"Traduzione\" con unità \"Parola\".",
    "CREATE_AS_24H_PROJECT": "Create as 24h project",
    "CUST_NAME": "Customer",
    "DUPLICATE_AS_QUOTE": "Duplica come preventivo",
    "DOWNLOAD_ALL_TARGET_FILES": "Scarica tutti i file target",
    "DUPLICATE_PROJECT_MSG": "A new project will be created for each target language. The first target language selected will be retained in the current project. To complete other projects you will be returned to the list of projects to complete the current one.",
    "DUPLICATE_WORKFLOW_MSG_1": "Verrà creato un nuovo ",
    "DUPLICATE_WORKFLOW_MSG_2": " utilizzando quello selezionato come modello. Sarà possibile effettuare modifiche.",
    "EXTRA_SERVICES": "Additional services",
    "EXTRA_SERVICES_CLIENT": "Additional services to be offered to the customer in addition to the expected work cycle",
    "INCOMPLETE_LIST_ERR": "Incomplete list for task",
    "INVOICES": "Invoices",
    "LANDOOR_ADMIN_FILES": "Documenti amministrativi non condivisi con il cliente:",
    "LOAD_TRADOS_LOG": "Upload Trados log",
    "LOAD_TRADOS_LOG_ERR": "The file is not valid",
    "LOAD_TRADOS_LOG_SUCC": "File successfully uploaded.",
    "NEW_WORKFLOW": "New request",
    "NEXT_STEP_DATE": "Data prossimo step",
    "NO_LIST_ERR": "No list for task",
    "NO_PM_ASSOCIATED": "No associated PM",
    "NO_PM_SELECTED": "No PM selected in the project info.",
    "NO_TARGET_FILES_ERR": "Nessun file target da scaricare.",
    "NO_REVISION_17100_ERR": "Enter at least one task 'Revision 17100' in project",
    "NOT_STARTED": "Not started",
    "OFFER_INCOMPLETE_ERROR": "Rivedi e salva i dati nella tab \"Offerta\" prima di richiedere l'approvazione al cliente",
    "OPTION_STANDARD": "Select a standard service from your list",
    "OPTION_STANDARD_TOOLTIP": "You are only offered the services that you use most frequently",
    "OPTION_FULL": "Select a service from the complete list",
    "OPTION_FULL_TOOLTIP": "You are offered all the services that Landoor offers",
    "OPTION_24H": "Select a standard project (24h) from your list",
    "OPTION_24H_TOOLTIP": "You are offered recurring projects set up by the project manager according to your needs",
    "OPTION_TEMPLATE": "Seleziona un progetto template dalla tua lista",
    "OPTION_TEMPLATE_TOOLTIP": "Ti vengono proposti i progetti ricorrenti impostati dal project manager in funzione delle tue esigenze. Potrai apportare modifiche al progetto selezionato",
    "ORIGIN": "Origin of order",
    "PROJECT_DETAIL": "Project detail",
    "SAVE_AS_24H": "Save as 24h project",
    "SAVE_AS_24H_MSG": "Save as 24h project?",
    "SAVE_AS_TEMPLATE": "Salva come progetto Template",
    "SAVE_AS_TEMPLATE_MSG": "Salvare come progetto Template?",
    "SAVE_CONTRIBUTOR_NOTES": "Save notes",
    "SAVE_CONTRIBUTOR_NOTES_ERR": "The notes could not be saved. Please try again later.",
    "SAVE_CONTRIBUTOR_NOTES_SUCC": "Notes saved. They will be visible to t Project Manager.",
    "SAVE_DRAFT": "Save draft",
    "SAVE_DRAFT_INFO": "Click on <b>Save draft</b> to complete and send the request at a later time.",
    "SELECT_BILLING_COMPANY": "Choose the recipient of the invoice",
    "SELECT_BILLING_COMPANY_MESSAGE": "Da selezionare in caso di fatturazione a un’altra divisione, sede, ragione sociale, cliente finale con diversa partita iva rispetto a quella riportata sopra in \"Dati fatturazione\"",
    "SELECT_PROJECT": "Select a project",
    "SEND_BILLING_DATA_REQUEST": "Invia richiesta conferma",
    "SEND_BILLING_DATA_REQUEST_ERR": "Non è al momento possibile inviare la richiesta. Riprova più tardi.",
    "SEND_BILLING_DATA_REQUEST_NO_EMAIL": "Questo cliente non ha un indirizzo email valido.",
    "SEND_BILLING_DATA_REQUEST_MSG": "Inviare al cliente una richiesta di conferma dei dati di fatturazione?",
    "SEND_BILLING_DATA_REQUEST_SUCC": "Richiesta inviata correttamente all'indirizzo ",
    "SEND_CONTRIBUTORS_MAILS": "Invia email ai collaboratori",
    "SEND_CONTRIBUTORS_MAILS_MSG": "Inviare le richieste di disponibilità ai collaboratori?",
    "SEND_OFFER_TO_CUSTOMER": "Send offer to the customer",
    "SEND_OFFER_TO_CUSTOMER_MSG": "Do you want to send the offer to the customer?",
    "SEND_OFFER_TO_CUSTOMER_SUCCESS": "Offer sent to the customer",
    "SEND_ORDER_REQUEST": "Do you want to submit the order?",
    "SEND_QUOTE_REQUEST": "Do you want to submit the quote request?",
    "SEND_REQUEST_CONFIRM_SUCCESS": "Request successfully submitted",
    "SHARE_ALL_FILES": "Condividi file sorgente",
    "SHARE_ALL_FILES_MSG": "Condividere tutti i file sorgente dei progetti?",
    "SHARED_ADMIN_FILES": "Documenti amministrativi caricati dal cliente:",
    "STARTED": "Started",
    "START_PROJECT": "Start project",
    "START_PROJECT_MSG": "Start the project?",
    "START_TASK": "Start task",
    "SUMMARY_QUOTE": "Quote summary",
    "TASK": "Task",
    "TEMPLATE_PROJECTS": "Template projects",
    "VOICE": "Voice",
    "OPEN_DOCUMENTAL": "Open in documental",
    "OPEN_LAST_TARGET": "Apri la cartella target dell'ultimo task",
    "COPY_PATH": "Copia percorso FS",
    "COPY_PATH_SUCCESS": "Il percorso è stato copiato negli appunti",
    "COPY_PATH_ERROR": "Il browser in uso non supporta la copia del percorso",
    "PACKAGE_EXPIRES_AT": "Data scadenza pacchetto"
  },
  "VALIDATION": {
    "DATE": "Invalid date",
    "DECIMAL_SEP": "Decimals separated by,",
    "INTEGER": "Enter a whole number",
    "MAX": "Minimum value: {{max}}",
    "MAX_LENGTH": "{{Length}} maximum character length",
    "MIN": "Minimum value: {{min}}",
    "MIN_LENGTH": "{{Length}} minimum character length",
    "NO_FILES_FOR_PROJECT": "Enter at least one source file in project {{code}} or specify future upload mode",
    "NO_PROJECTS_ERR": "Enter at least one project",
    "NO_SERVICES_ERR": "Enter at least one service per project",
    "NUMBER": "Enter a valid number",
    "NUMBER_LENGTH": "Enter a number of {{length}} digits",
    "NUMBER_RANGE": "Enter a number from {{from}} to {{to}}",
    "PASSWORD_DIFFENTE": "The passwords are different",
    "PECSDI": "Enter PEC or SDI code",
    "PREFIX_VAT": "Enter 2 capital letters",
    "REQUIRED": "Required field",
    "REQUIRED_EMAIL": "Enter an e-mail address",
    "REQUIRED_PASSWORD": "Enter the password",
    "TAX_CODE": "Enter a valid tax code",
    "URL": "Enter a valid web address (lowercase letters only)",
    "VALID_AMOUNT": "Enter a valid amount",
    "VALID_EMAIL": "Enter a valid e-mail address",
    "ALPHANUMERIC": "Only alphanumeric characters are allowed"
  },
  "quality": {
    "TITLE": "Quality control",
    "QUESTIONS": {
      "Q_1": "Give a detailed description of your quality control process.",
      "Q_2": "Do you complete a checklist before delivering the translation? What does it involve?",
      "Q_3": "How do you ensure that your translations meet the required standards?",
      "Q_4": "How do you try to ensure that there are no errors or oversights in the texts, such as typing errors?",
      "Q_5": "How do you ensure that knowledge and skills are shared within your teams?",
      "Q_6": "How do you capitalize on customer feedback?",
      "Q_7": "Is there a quality control manager for each language combination in your company?",
      "Q_8": "What are their duties?",
      "Q_9": "What type of checks do you carry out on projects managed outside the company/sub-contracted?",
      "Q_10": "How do you ensure consistency across texts in large projects managed by more than one translator?",
      "Q_11": "Do you have experience in content-sharing and TM-sharing? If so, what type?",
      "Q_12": "Are you able to provide transcreation or marketisation services? If yes, specify."
    }
  },
  "story": {
    "TITLE": "Company history",
    "QUESTIONS": {
      "Q_2": "Describe the structure of the company",
      "Q_3": "Have the company or its executives ever been involved in bankruptcy, litigation, mergers or acquisitions?",
      "Q_4": "List the company’s most important customers, specifying their business sector, the year in which collaboration began, the turnover and services provided.",
      "Q_5": "What were the three most significant company results for the year just ended?",
      "Q_6": "What are your three main goals for the current year?",
      "Q_7": "Where is production located?",
      "Q_8": "The industry is undergoing a period of rapid change. Instead of proceeding directly to large projects with medium-long timescales, increasingly more customers today require translations of small-medium documents with short timescales.  How do you approach this situation? Would you be willing to group all the projects carried out into a single cumulative order at the end of the month?"
    }
  },
  "vendor": {
    "TITLE": "Vendor management",
    "QUESTIONS": {
      "Q_1": "How are the company's external resources managed (suppliers - freelance contributors)? Do you have a Vendor Manager?",
      "Q_2": "What is the qualification process for these resources? Describe it in detail.",
      "Q_3": "What are the minimum requirements for the qualification of all types of resources?",
      "Q_4": "Do you do regular follow-ups with freelance contributors?",
      "Q_5": "How many resources do you regularly collaborate with?",
      "Q_6": "Which language combinations can you handle internally thanks to your in-house team?"
    }
  },
  "CURRENT_LIST_SINCE": "Current list from the",
  "HISTORY": "Old",
  "OLD_LIST_OF": "List of the",
  "statistics": {
    "TITLE": "Statistiche"
  },
  "leads": {
    "TITLE": "Leads"
  },
  "agreements": {
    "1": "Having viewed the policy of single-member company LANDOOR S.r.l. (“Landoor” for short) of 13.06.2018, published on www.landoor.com, I consent to processing of my personal data by Landoor pursuant to the new EU Regulation 2016/679 in order that it may:",
    "2": "I consent to the processing of my personal data by Landoor in order to:",
    "3": "I consent to the processing of my personal data by Landoor in order to:",
    "4": "The duration of the processing is specified in the Policy. I am aware that I have the right to withdraw my consent, at any time and for any reason, for one or more of the above purposes by using the “Data subject withdrawal form” which you shall provide me if and when requested.",
    "1A": "1.a. provide the services that are the subject of the contracts that exist between the undersigned and the Data Controller;",
    "1B": "1.b. fulfill the pre-contractual, contractual and fiscal obligations deriving from the relationships established with the undersigned;",
    "1C": "1.c. fulfill the obligations established by law, by a regulation, by EU law or by an order from the Authority (for example, on anti-money laundering);",
    "1D": "1.d. exercise the rights of the Data Controller, for example the right of defence before a court;",
    "2A": "2. receive, via e-mail, post and/or telephone, the following information from the Data Controller: invitations to events, training materials, newsletters, communications on products or services, and customer satisfaction surveys on the level of quality of the services;",
    "3A": "3. receive, via e-mail, post and/or telephone, the following information from third parties (e.g. business partners): invitations to events, training materials, newsletters, communications on products or services.",
    "CHECKED_BY_PM": "Dati forniti da committente su supporto esterno a Door24",
    "REQUIRED": "La spunta dei consensi è necessaria per procedere con la registrazione.",
    "WITHDRAWED": "Utente cancellato o con consenso ritirato",
    "WITHDRAWING": "Cancellazione"
  }
}
